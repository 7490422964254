import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import firebase from "firebase/compat";
import {CommonModule} from "@angular/common";
import {LoadingService} from "../loading/loading.service";
import FirebaseError = firebase.FirebaseError;
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  // todo: modes should be an enum
  @Input() isSignUpMode = false;
  @Output() loginSuccess = new EventEmitter<void>();
  @Output() modalTitle = new EventEmitter<string>(); // todo don: very bad way to change title in parent component. Find better way! ... probably found one already ;)

  credentialsForm: FormGroup;

  signupSuccess = false;
  resetPasswordSuccess = false;
  signupError!: string;
  loginError!: string;
  forgotPasswordError!: string;
  isForgotPasswordMode = false;

  constructor(
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService
  ) {
    // this.modalTitle.emit('Login');

    this.credentialsForm = new FormGroup({
      firstName: new FormControl({value: '', disabled: this.isSignUpMode}, ),
      name: new FormControl({value: '', disabled: this.isSignUpMode}, ),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(6)
        ])
    });
  }

  private initMessages() {
    this.signupSuccess = false;
    this.resetPasswordSuccess = false;
    this.signupError = "";
    this.loginError = "";
    this.forgotPasswordError = "";
  }

  onSubmit() {
    this.initMessages();
    if (this.credentialsForm.valid) {
      if (this.isSignUpMode) {
        this.signUp();
      } else if (this.isForgotPasswordMode) {
        this.resetPassword();
      } else {
        this.login();
      }
    }
  }

  // fixme: this is not done when externally closing the modal and opening from somewhere, where it is initialized differently
  toggleMode() {
    this.isSignUpMode = !this.isSignUpMode; // Toggle the mode
    this.modalTitle.emit(this.isSignUpMode ? 'Sign Up' : 'Login');
    this.initMessages();
  }

  signUp() {
    if (this.credentialsForm.valid) {
      const {firstName, name, email, password} = this.credentialsForm.value;

      this.loadingService.show();

      this.authenticationService
        .signUp(email, password, firstName, name)
        .subscribe({
          next: () => {
            console.log('Sign Up Successful');
            this.loadingService.hide();
            // this.credentialsForm.reset();
            this.toggleMode();
            this.signupSuccess = true;
          },
          error: (error: HttpErrorResponse) => {
            console.error(`Sign up error: ${error.message} - ${error.error}`);
            this.signupError = `${error.error}`;
            this.loadingService.hide();
          }
        })
    }
  }

  login() {
    if (this.credentialsForm.valid) {
      const {email, password} = this.credentialsForm.value;

      this.loadingService.show();

      this.authenticationService
        .login(email, password)
        .then(() => {
          console.log('Login Successful');
          this.credentialsForm.reset();
          this.loginSuccess.emit();
        })
        .catch((error: FirebaseError) => { //todo don: There shouldn't be a dependency to Firebase here -> abstract
          console.error(`Login error: ${error.message} - ${error.code}`);
          this.loginError = `${error.message}`;
        })
        .finally(() => this.loadingService.hide());
    }
  }

  onForgotPasswordClicked() {
    this.isForgotPasswordMode = true;
    const passwordInput = this.credentialsForm.get('password');
    passwordInput?.reset();
    passwordInput?.disable();
    this.modalTitle.emit('Forgot Password');
  }

  onResetPasswordBackClicked() {
    this.initMessages();
    this.isForgotPasswordMode = false;
    this.credentialsForm.get('password')?.enable();
    this.modalTitle.emit('Login');
  }

  resetPassword() {
      const {email} = this.credentialsForm.value;

      this.loadingService.show();

      this.authenticationService
        .resetPassword(email)
        .then(() => {
          console.log('Password reset initialized');
          this.resetPasswordSuccess = true;
        })
        .catch((error: FirebaseError) => { //todo don: There shouldn't be a dependency to Firebase here -> abstract
          console.error(`Reset password error: ${error.message} - ${error.code}`);
          this.forgotPasswordError = `${error.message}`;
        })
        .finally(() => this.loadingService.hide());
  }

  // logout() {
  //   this.authenticationService
  //     .logout()
  //     .then(() => {
  //       console.log('Logout Successful');
  //     })
  //     .catch((error: FirebaseError) => {
  //       console.error(error.message);
  //     })
  //     .then(() => this.router
  //       .navigate(['/'])
  //       .then(() => {
  //         window.location.reload();
  //       })
  //     );
  // }

  // private parseErrorBody(errorBody: any): any {
  //   try {
  //     return JSON.parse(errorBody);
  //   } catch {
  //     return errorBody;
  //   }
  // }
}
