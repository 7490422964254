import {FileService} from './file.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FullDelegate} from '../domain/instant';
import {accessDelegateHeader, Header} from '../../api/resource/resource-access.service';
import {environment} from '../../../environments/environment';
import {Observable, tap} from 'rxjs';
import {Injectable} from '@angular/core';
import {ResourceId} from "../domain/resource";
import {FileDto} from "./file-dto";

@Injectable({
  providedIn: 'root'
})
export class FileServiceImplementation extends FileService {

  private static filesUrl: string = `${environment.apiPath}/files`

  constructor(private http: HttpClient) {
    super();
  }

  upload(file: File, delegate?: FullDelegate | null): Observable<{ url: string }> {
    const idPath = delegate ? '/' + delegate.uuid : '';
    const delegateHeader: Header | undefined = delegate ? accessDelegateHeader(delegate) : undefined;

    const path = `${FileServiceImplementation.filesUrl}${idPath}`;

    // Construct headers
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'X-File-Name': encodeURIComponent(file.name),
      'X-File-Type': file.type
    });

    // Add delegate header if it exists
    if (delegateHeader) {
      headers = headers.set(delegateHeader.key, delegateHeader.value);
    }

    // Use HttpClient to upload the file via PUT
    return this.http.put<{ url: string }>(path, file, {headers});
  }

  get(communityId: ResourceId): Observable<FileDto[]> {
    return this.http.get<FileDto[]>(FileServiceImplementation.filesUrl
      + '/community'
      + '/' + communityId.creationTimestamp
      + '/' + communityId.uuid
    );
  }

  delete(url: string): Observable<void> {
    return this.http.delete<void>(url);
  }
}
