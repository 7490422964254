import { APP_INITIALIZER, ApplicationConfig, isDevMode, LOCALE_ID, SecurityContext } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi
} from "@angular/common/http";
import { FirebaseAuthenticatorInterceptor } from "./authentication/firebase/firebase-authenticator.interceptor";
import { loadingInterceptor } from "./loading/loading.interceptor";
import { provideMarkdown } from "ngx-markdown";
import { PostService } from "./community/post/post.service";
import { FeedService } from "./community/feed/feed.service";
import { CollectionService } from "./community/collection/collection.service";
import { ProfileService } from "./community/profile/profile.service";
import { ResourceAccessService } from './api/resource/resource-access.service';
import { AuthenticationService } from './authentication/authentication.service';
import { UserContentApiService } from './api/user/user-content-api.service';
import { AccessSetupApiService } from './api/user/access-setup-api.service';
import { ProfileServiceMock } from './community/profile/profile.service.mock';
import { PostServiceMock } from './community/post/post.service.mock';
import { FeedServiceMock } from './community/feed/feed.service.mock';
import { CollectionServiceMock } from './community/collection/collection.service.mock';
import { ResourceAccessServiceMock } from './api/resource/resource-access.service.mock';
import { AuthenticationServiceMock } from './authentication/authentication.service.mock';
import { UserContentApiServiceMock } from './api/user/user-content-api.service.mock';
import { AccessSetupApiServiceMock } from './api/user/access-setup-api.service.mock';
import { FileService } from './community/files/file.service';
import { FileServiceMock } from './community/files/file.service.mock';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { UserApiService } from './api/user/user-api.service';
import { UserApiServiceMock } from './api/user/user-api.service.mock';
import { ProfileServiceImplementation } from './community/profile/profile.service.implementation';
import { PostServiceImplementation } from './community/post/post.service.implementation';
import { FeedServiceImplementation } from './community/feed/feed.service.implementation';
import { CollectionServiceImplementation } from './community/collection/collection.service.implementation';
import { ResourceAccessServiceImplementation } from './api/resource/resource-access.service.implementation';
import { FirebaseAuthenticationService } from './authentication/firebase/firebase-authentication.service';
import { UserContentApiServiceImplementation } from './api/user/user-content-api.service.implementation';
import { UserApiServiceImplementation } from './api/user/user-api.service.implementation';
import { FileServiceImplementation } from './community/files/file.service.implementation';
import {AccessSetupApiServiceImplementation} from "./api/user/access-setup-api.service.implementation";
import { CommunitySubscriptionService } from './community/community-subscription/community-subscription.service';
import {
  CommunitySubscriptionServiceMock
} from './community/community-subscription/community-subscription.service.mock';
import {
  CommunitySubscriptionServiceImplementation
} from './community/community-subscription/community-subscription.service.implementation';
import { ProductService } from './community/product/product.service';
import { ProductServiceMock } from './community/product/product.service.mock';
import { ProductServiceImplementation } from './community/product/product.service.implementation';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { appReducers } from './store/app.reducers';
import { CommunityEffects } from './store/community/community.effects';
import { CommunityRouteActionService } from './community/community-services/community-route-action.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeCh from '@angular/common/locales/de-CH';
import localeDeAt from '@angular/common/locales/de-AT';
import { PurchaseManagementApiService } from './api/membership/purchase-management-api.service';
import { PurchaseManagementApiServiceMock } from './api/membership/purchase-management-api.service.mock';
import {
  PurchaseManagementApiServiceImplementation
} from './api/membership/purchase-management-api.service.implementation';

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeDeCh, 'de-CH');
registerLocaleData(localeDeAt, 'de-AT');

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: () => {
        if (navigator.language) {
          return ['de-DE', 'de-CH', 'de-AT', 'en-US']
            .some(lang => navigator.language === lang) ? navigator.language : 'en-US'
        }
        return 'en-US';
      }, // Use browser locale
    },
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()), // todo: use only one provideHttpClient(...)
    {provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthenticatorInterceptor, multi: true},

    /*** App Services without DI ***/
    {
      provide: APP_INITIALIZER,
      useFactory: (routeActionService: CommunityRouteActionService) => () => {},
      deps: [CommunityRouteActionService],
      multi: true,
    },

    /*** Mocks ***/
    // {provide: ProfileService, useClass: ProfileServiceMock},
    // {provide: PostService, useClass: PostServiceMock},
    // {provide: ProductService, useClass: ProductServiceMock},
    // {provide: FeedService, useClass: FeedServiceMock},
    // {provide: CollectionService, useClass: CollectionServiceMock},
    // {provide: ResourceAccessService, useClass: ResourceAccessServiceMock},
    // {provide: AuthenticationService, useClass: AuthenticationServiceMock},
    // {provide: UserContentApiService, useClass: UserContentApiServiceMock},
    // {provide: AccessSetupApiService, useClass: AccessSetupApiServiceMock},
    // {provide: UserApiService, useClass: UserApiServiceMock},
    // {provide: CommunitySubscriptionService, useClass: CommunitySubscriptionServiceMock},
    // {provide: FileUploadService, useClass: FileServiceMock},
    // {provide: PurchaseManagementApiService, useClass: PurchaseManagementApiServiceMock},

    /*** Implementations ***/
    {provide: ProfileService, useClass: ProfileServiceImplementation},
    {provide: PostService, useClass: PostServiceImplementation},
    {provide: ProductService, useClass: ProductServiceImplementation},
    {provide: FeedService, useClass: FeedServiceImplementation},
    {provide: CollectionService, useClass: CollectionServiceImplementation},
    {provide: ResourceAccessService, useClass: ResourceAccessServiceImplementation},
    {provide: AuthenticationService, useClass: FirebaseAuthenticationService},
    {provide: UserContentApiService, useClass: UserContentApiServiceImplementation},
    {provide: AccessSetupApiService, useClass: AccessSetupApiServiceImplementation},
    {provide: UserApiService, useClass: UserApiServiceImplementation},
    {provide: FileService, useClass: FileServiceImplementation},
    {provide: CommunitySubscriptionService, useClass: CommunitySubscriptionServiceImplementation},
    {provide: PurchaseManagementApiService, useClass: PurchaseManagementApiServiceImplementation},


    provideHttpClient(withInterceptors([loadingInterceptor]), withFetch()),
    provideMarkdown({
      sanitize: SecurityContext.NONE,
      markedExtensions: [gfmHeadingId()]
    }),
    provideStore(appReducers),
    provideEffects([CommunityEffects]),
    provideStoreDevtools({
      // maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      // trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      // traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      // connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
  ],
};
