import {Routes} from "@angular/router";
import {DiscoverComponent} from "./profile/discover/discover.component";
import {CollectionComponent} from "./collection/collection/collection.component";
import {CommunityHomeComponent} from "./community-home/community-home.component";
import {FeedsComponent} from "./feed/feeds/feeds.component";
import {CollectionsComponent} from "./collection/collections/collections.component";
import {FeedStandaloneComponent} from "./feed/standalone/feed-standalone.component";
import {AppRoutes} from '../app.routes';
import {CommunitySubscriptionPageComponent} from './community-subscription/community-subscription-page.component';
import {ProductsComponent} from './product/products/products.component';
import {ProductComponent} from './product/product/product.component';
import {CommunityBlogComponent} from './community-blog/community-blog.component';
import {SubscriptionPlan} from '../api/membership/domain/subscription-plan';
import {resourceAccessGuard} from "../guards/resource-access.guard";
import {authenticationGuard} from "../guards/authentication.guard";
import {communityWriteAccessGuard} from "../guards/community-write-access.guard";

export interface MembershipRouteParams {
  purchaseSuccess?: string;
  activePlan?: SubscriptionPlan;
  requiredPlan?: SubscriptionPlan;
}

export const COMMUNITY_UUID = 'communityUuid';
export const COMMUNITY_CREATION_TIMESTAMP = 'communityCreationTimestamp';
export const CREATION_TIMESTAMP = 'creationTimestamp';
export const UUID = 'uuid';
export const CHAPTER_ID = 'elementId'

export const PURCHASE_SUCCESS_QUERY_PARAM = 'purchaseSuccess'
export const MEMBERSHIP_ACTIVE_PLAN_QUERY_PARAM = 'activePlan'
export const MEMBERSHIP_REQUIRED_PLAN_QUERY_PARAM = 'requiredPlan'

export class CommunityRoutes {
  static subDirs = {
    profile: 'profile',
    feed: 'feed',
    collection: 'collection',
    product: 'product',
    blog: 'blog',
    membership: 'membership',
    operations: 'operations'
  };

  static membership(communityCreationTimestamp: number, communityId: string, params?: MembershipRouteParams): string {
    let url = `/${AppRoutes.community}/${CommunityRoutes.subDirs.membership}/${communityCreationTimestamp}/${communityId}`;

    // todo appending query params should be done better!
    if (params?.purchaseSuccess || params?.activePlan || params?.requiredPlan) {
      url += '?';
    }
    if (params?.purchaseSuccess != undefined) {
      url += url.slice(-1) === '?' ? '' : '&';
      url += `${PURCHASE_SUCCESS_QUERY_PARAM}=${params.purchaseSuccess}`;
    }
    if (params?.activePlan != undefined) {
      url += url.slice(-1) === '?' ? '' : '&';
      url += `${MEMBERSHIP_ACTIVE_PLAN_QUERY_PARAM}=${params.activePlan}`;
    }
    if (params?.requiredPlan != undefined) {
      url += url.slice(-1) === '?' ? '' : '&';
      url += `${MEMBERSHIP_REQUIRED_PLAN_QUERY_PARAM}=${params.requiredPlan}`;
    }
    return url;
  }

  static operations(communityCreationTimestamp: number, communityId: string): string {
    return `/${AppRoutes.community}/${CommunityRoutes.subDirs.operations}/${communityCreationTimestamp}/${communityId}`;
  }

  static communities() {
    return '/community/profile';
  }

  static community(creationTimestamp: number, id: string) {
    return '/community/profile' + '/' + creationTimestamp + '/' + id;
  }

  static blog(creationTimestamp: number, id: string) {
    return '/community/' + this.subDirs.blog + '/' + creationTimestamp + '/' + id;
  }

  static feeds(communityCreationTimestamp: number, communityId: string) {
    return '/community/feed' + '/' + communityCreationTimestamp + '/' + communityId;
  }

  static feed(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string) {
    return '/community/feed' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id;
  }

  static collections(communityCreationTimestamp: number, communityId: string) {
    return '/community/collection' + '/' + communityCreationTimestamp + '/' + communityId;
  }

  static collection(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string) {
    return '/community/collection' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id;
  }

  static products(communityCreationTimestamp: number, communityId: string) {
    return '/community/product' + '/' + communityCreationTimestamp + '/' + communityId;
  }

  static product(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string, purchaseSuccess?: string) {
    let url = '/community/product' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id;
    if (purchaseSuccess != undefined) {
      url += `?${PURCHASE_SUCCESS_QUERY_PARAM}=${purchaseSuccess}`;
    }
    return url;
  }

  static chapter(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string, chapterId: string) {
    return '/community/collection' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id + '/' + chapterId;
  }
}

export const routes: Routes = [{
  path: '',
  // providers:[
  //   {provide: TitleStrategy, useClass: CommunityTitleStrategy}
  // ],
  children: [
    {path: CommunityRoutes.subDirs.profile, component: DiscoverComponent},
    {
      path: CommunityRoutes.subDirs.profile + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      component: CommunityHomeComponent
    },
    {
      path: CommunityRoutes.subDirs.blog + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      component: CommunityBlogComponent
    },

    {
      path: CommunityRoutes.subDirs.product + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      component: ProductsComponent
    },
    {
      path: CommunityRoutes.subDirs.product + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID,
      component: ProductComponent
      // canActivate: [ResourceAccessGuard]
    },

    {
      path: CommunityRoutes.subDirs.feed + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      component: FeedsComponent,
      // canActivate: [resourceAccessGuard]
    },
    {
      path: CommunityRoutes.subDirs.feed + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID,
      component: FeedStandaloneComponent,
      canActivate: [resourceAccessGuard]
    },

    {
      path: CommunityRoutes.subDirs.collection + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      component: CollectionsComponent,
      // canActivate: [resourceAccessGuard]
    },
    {
      path: CommunityRoutes.subDirs.collection + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID,
      component: CollectionComponent,
      canActivate: [resourceAccessGuard]
    },
    {
      path: CommunityRoutes.subDirs.collection + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID + '/:' + CHAPTER_ID,
      component: CollectionComponent,
      canActivate: [resourceAccessGuard]
    },

    {
      path: CommunityRoutes.subDirs.membership + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      component: CommunitySubscriptionPageComponent
    },

    {path: '', redirectTo: CommunityRoutes.subDirs.profile, pathMatch: 'full'},
    {
      path: CommunityRoutes.subDirs.operations + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID,
      loadChildren: () => import('./operations/community-operations.routes').then(m => m.routes),
      // Schwanzbeisser:
      // canActivate: [authenticationGuard, communityWriteAccessGuard]
      canActivate: [authenticationGuard]
    }
  ]
}];
