import {HttpClient, HttpContext, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, EMPTY, map, Observable, switchMap, take, timeout} from "rxjs";
import {accessDelegateHeader} from "../../api/resource/resource-access.service";
import {FullDelegate} from "../domain/instant";

export abstract class AbstractDelegateHttpService {

  constructor(protected httpClient: HttpClient) {
  }

  protected abstract getDelegate(): Observable<FullDelegate>;

  // General method for adding delegate headers, used across all HTTP methods
  protected addDelegateHeaders(
    existingHeaders?: HttpHeaders | { [p: string]: string | string[] } | undefined
  ): Observable<HttpHeaders> {
    return this.getDelegate().pipe(
      take(1),
      map(delegate => {
        let headers: HttpHeaders;

        if (existingHeaders instanceof HttpHeaders) {
          headers = existingHeaders;
        } else if (existingHeaders && typeof existingHeaders === 'object') {
          headers = new HttpHeaders(existingHeaders);
        } else {
          headers = new HttpHeaders();
        }

        const delegateHeader = accessDelegateHeader(delegate);
        return headers.set(delegateHeader.key, delegateHeader.value);
      }),
      timeout(200),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.log('Delegate not found.');
        } else {
          console.log('An error occurred:', error);
        }
        return EMPTY;
      })
    );
  }


  // Delegate GET request, same signature as HttpClient.get
  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    transferCache?: {
      includeHeaders?: string[];
    } | boolean;
  }): Observable<T> {
    return this.addDelegateHeaders(options?.headers).pipe(
      switchMap(headers => {
        const updatedOptions = {...options, headers};
        return this.httpClient.get<T>(url, updatedOptions);
      })
    );
  }

  // Delegate POST request, same signature as HttpClient.post
  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    transferCache?: {
      includeHeaders?: string[];
    } | boolean;
  }): Observable<T> {
    return this.addDelegateHeaders(options?.headers).pipe(
      switchMap(headers => {
        const updatedOptions = {...options, headers};
        return this.httpClient.post<T>(url, body, updatedOptions);
      })
    );
  }

  // Delegate PUT request, same signature as HttpClient.put
  put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.addDelegateHeaders(options?.headers).pipe(
      switchMap(headers => {
        const updatedOptions = {...options, headers};
        return this.httpClient.put<T>(url, body, updatedOptions);
      })
    );
  }

  // Delegate DELETE request, same signature as HttpClient.delete
  delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any | null;
  }): Observable<T> {
    return this.addDelegateHeaders(options?.headers).pipe(
      switchMap(headers => {
        const updatedOptions = {...options, headers};
        return this.httpClient.delete<T>(url, updatedOptions);
      })
    );
  }

  // Delegate request for general requests (HEAD, PATCH, etc.)
  request<T>(method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
    transferCache?: {
      includeHeaders?: string[];
    } | boolean;
  }): Observable<T> {
    return this.addDelegateHeaders(options?.headers).pipe(
      switchMap(headers => {
        const updatedOptions = {...options, headers};
        return this.httpClient.request<T>(method, url, updatedOptions);
      })
    );
  }
}
