import {Routes} from '@angular/router';
// import {FeaturesComponent} from "./features/features.component";
import {authenticationGuard} from "./guards/authentication.guard";
import {PrivacyComponent} from "./legal/privacy/privacy.component";
import {DisclaimerComponent} from "./legal/disclaimer/disclaimer.component";
import {TermsComponent} from "./legal/terms/terms.component";

export enum AppRoutes {
  community = 'community',
  plans = 'plans',
  // legal = 'legal',
  privacy = 'privacy',
  disclaimer = 'disclaimer',
  terms = 'terms',
  myContent = 'my-content'
}

export const routes: Routes = [
  {path: '', redirectTo: AppRoutes.community, pathMatch: 'full'},
  {
    path: AppRoutes.community,
    // providers: [
    //   {provide: TitleStrategy, useClass: CommunityTitleStrategy}
    // ],
    loadChildren: () => import('./community/community.routes').then(m => m.routes)
  },
  // {path: '', component: PostComponent},
  // {path: 'feed-creator', component: FeedCreatorViewComponent},
  // {path: 'features', component: FeaturesComponent},
  // {path: AppRoutes.plans, component: PlansComponent},
  // {path: AppRoutes.legal, component: LegalComponent},
  {path: AppRoutes.privacy, component: PrivacyComponent},
  {path: AppRoutes.disclaimer, component: DisclaimerComponent},
  {path: AppRoutes.terms, component: TermsComponent},
  {
    path: AppRoutes.myContent,
    canActivate: [authenticationGuard],
    loadChildren: () => import('./secure/my-content-routes').then(m => m.routes)
  }
];
