import {Injectable} from '@angular/core';
import {FullDelegate} from "../domain/instant";
import {Observable} from 'rxjs';
import {RootResourceId} from "../domain/resource";
import {FileDto} from "./file-dto";

@Injectable({
  providedIn: 'root'
})
export abstract class FileService {

  abstract upload(file: File, delegate?: FullDelegate | null): Observable<{ url: string }>;

  abstract get(communityId: RootResourceId): Observable<FileDto[]>;

  abstract delete(url: string): Observable<void>;

}
